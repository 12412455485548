import authorizationAPI from '@/modules/api/authorization'

function isUserBatys () {
  return authorizationAPI.isRoleBatys()
}

function isUserErtys () {
  return authorizationAPI.isRoleErtys()
}

function isUserKazykurt () {
  return authorizationAPI.isRoleKazykurt()
}

function isUserPtc () {
  return authorizationAPI.isRolePtc()
}

function getDocumentInfo (docType) {
  let documentTitle = ''
  let attributes = []
  let workplaceAttributes = []
  let jasperPath = ''
  let attributeRequestsName = ''
  let attributeRequestName = ''
  switch (docType) {
    case 'vu20':
    case 'vu20a':
      documentTitle = 'ВУ-20a'
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Компания отправитель', value: 'senderCompany' },
        { label: 'Компания получатель', value: 'receiverCompany' },
        { label: 'Станция промывки-пропарки', value: 'station20a' },
        { label: 'Под погрузку/выгрузку', value: 'inGood20a' },
        { label: 'Путь', value: 'path' },
        { label: 'Тип обработки', value: 'processingType' },
        { label: 'На завод', value: 'toFactory' },
        { label: 'На перекачку', value: 'forPumpingVu20a' },
        { label: 'Сменный Мастер', value: 'master' },
        { label: 'Бригадир', value: 'brigadir' },
        { label: 'Оператор', value: 'operator' },
        { label: 'Старший пропарщик', value: 'seniorSteamer' },
        { label: 'Слесарь подвижного состава', value: 'locksmith' },
        { label: 'Приёмщик нефтеналивных ёмкостей', value: 'receiverOfOilTanks' },
        { label: 'Компания Собственник', value: 'companyOwner' },
        { label: 'Бригадир ТОР', value: 'brigadirTOR' },
        { label: 'Представитель РТС Оператор', value: 'ptc' },
        { label: 'Представитель ПКОП', value: 'utsl' }
      ]
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' },
        { label: 'Ранее перевозимый груз', value: 'outGood20a' },
        { label: 'Операция по ГОСТу', value: 'gostOperation20a' },
        { label: 'Тип вагона', value: 'wagonСalibration' },
        { label: 'Примечание', value: 'primechaniyeVu' }
        // { label: 'endTime20', value: 'endTime20', type: 'date' },
        // { label: 'startTime20', value: 'startTime20', type: 'date' },
        // { label: 'tripleValve', value: 'startTime20', type: 'date' }
      ]
      jasperPath = 'http://192.168.120.148:8080/jasperserver/rest_v2/reports/alterdoc/vu20qr/vu20qr.html?id=346215&username=jasperadmin&password=jasperadmin'
      if (authorizationAPI.isUserTTK() || authorizationAPI.isUserPortocom()) {
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Компания отправитель', value: 'senderCompany' },
          { label: 'Компания получатель', value: 'receiverCompany' },
          { label: 'Станция промывки-пропарки', value: 'station20a' },
          { label: 'Под погрузку/выгрузку', value: 'inGood20a' },
          { label: 'Путь', value: 'path' },
          { label: 'Тип обработки', value: 'processingType' },
          { label: 'На завод', value: 'toFactory' },
          { label: 'На перекачку', value: 'forPumpingVu20a' },
          { label: 'Сменный Мастер', value: 'master' },
          { label: 'Бригадир', value: 'brigadir' },
          { label: 'Оператор', value: 'operator' },
          { label: 'Старший пропарщик', value: 'seniorSteamer' },
          { label: 'Слесарь подвижного состава', value: 'locksmith' },
          { label: 'Приёмщик нефтеналивных ёмкостей', value: 'receiverOfOilTanks' },
          { label: 'Компания Собственник', value: 'companyOwner' }
        ]
      }
      attributeRequestsName = 'attributeRequests'
      attributeRequestName = 'attributeRequest'
      if (isUserBatys) {
        attributes = attributes.filter(item => {
          return item.value !== 'companyOwner' && item.value !== 'inspectorPTO' && item.value !== 'brigadirTOR' && item.value !== 'ptc' && item.value !== 'stationTransceiver' && item.value !== 'utsl'
        })
        workplaceAttributes = workplaceAttributes.filter(item => {
          return item.value !== 'endTime20' && item.value !== 'startTime20' && item.value !== 'tripleValve'
        })
      } else if (isUserErtys) {
        attributes = attributes.filter(item => {
          return item.value !== 'toFactory' && item.value !== 'forPumpingVu20a' && item.value !== 'brigadir' && item.value !== 'seniorSteamer' && item.value !== 'locksmith'
        })
      } else {
        attributes = attributes.filter(item => {
          return item.value !== 'date20a' && item.value !== 'toFactory' && item.value !== 'forPumpingVu20a' && item.value !== 'brigadir' && item.value !== 'operator' && item.value !== 'seniorSteamer' && item.value !== 'locksmith' && item.value !== 'receiverOfOilTanks'
        })
        workplaceAttributes = workplaceAttributes.filter(item => {
          return item.value !== 'primechaniyeVu'
        })
      }
      break
    case 'vu19':
      documentTitle = 'ВУ-19'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Сменный мастер', value: 'master' },
        { label: 'Лаборанты', value: 'laborant' },
        { label: 'Сварщики', value: 'welders' },
        { label: 'Старший пропарщик', value: 'seniorSteamer' },
        { label: 'Пропарщик', value: 'steamerVu19' },
        { label: 'Бригадир ТОР', value: 'brigadirVu19' }
      ]
      attributeRequestsName = 'attributeRequestsVu19'
      attributeRequestName = 'attributeRequestVu19'
      if (isUserBatys) {
        attributes = attributes.filter(item => {
          return item.value !== 'steamerVu19' && item.value !== 'brigadirVu19'
        })
      } else if (isUserErtys) {
        attributes = attributes.filter(item => {
          return item.value !== 'steamerVu19' && item.value !== 'brigadirVu19' && item.value !== 'welders'
        })
      } else {
        attributes = attributes.filter(item => {
          return item.value !== 'seniorSteamer' && item.value !== 'welders'
        })
      }
      break
    case 'act':
      documentTitle = 'Акт'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Сменный мастер', value: 'masterAct' },
        { label: 'Промывальщик пропарщик', value: 'steamWasherAct' },
        { label: 'Оператор по учету вагонов-цистерн', value: 'operatorRegistrationAct' }
      ]
      attributeRequestsName = 'attributeRequestsAct'
      attributeRequestName = 'attributeRequestAct'
      break
    case 'vu23':
      documentTitle = 'ВУ23'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Станция вагонов', value: 'stationVu23' },
        { label: 'Время обнаружения', value: 'inTimeVu23', type: 'date' },
        { label: 'Путь', value: 'pathVu23' },
        { label: 'Наименование несправностей', value: 'neispravnostiVu23' },
        { label: 'Вид требуемого ремонта', value: 'typeRepairNewVu23' },
        { label: 'Место вагона для ремонта', value: 'repairPlaceNewVu23' },
        { label: 'Дата забраковки', value: 'repairDateNewVu23', type: 'date' },
        { label: 'Сменный мастер', value: 'masterVu23' },
        { label: 'Бригадир', value: 'brigadirVu23' },
        { label: 'Приемщик вагонов', value: 'receiverOfOilTanksVu23' },
        { label: 'Пропарщик', value: 'steamer' },
        { label: 'Старший пропарщик', value: 'seniorSteamer' },
        { label: 'Слесарь подвижного состава', value: 'locksmith' }
      ]
      if (isUserKazykurt()) {
        workplaceAttributes = [
          { label: 'Номер вагона', value: 'railcarVu23' },
          { label: 'Из под какого продукта', value: 'podSlivVu23' }
        ]
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Станция вагонов', value: 'stationVu23' },
          { label: 'Время обнаружения', value: 'inTimeVu23', type: 'date' },
          { label: 'Путь', value: 'pathVu23' },
          { label: 'Наименование несправностей', value: 'neispravnostiVu23' },
          { label: 'Вид требуемого ремонта', value: 'typeRepairNewVu23' },
          { label: 'Место вагона для ремонта', value: 'repairPlaceNewVu23' },
          { label: 'Дата забраковки', value: 'repairDateNewVu23', type: 'date' },
          { label: 'Подпись ДСП', value: 'signDSP' }
        ]
      }
      if (isUserErtys()) {
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Станция вагонов', value: 'stationVu23' },
          { label: 'Время обнаружения', value: 'inTimeVu23', type: 'date' },
          { label: 'Путь', value: 'pathVu23' },
          { label: 'Наименование несправностей', value: 'neispravnostiVu23' },
          { label: 'Вид требуемого ремонта', value: 'typeRepairNewVu23' },
          { label: 'Место вагона для ремонта', value: 'repairPlaceNewVu23' },
          { label: 'Дата забраковки', value: 'repairDateNewVu23', type: 'date' },
          { label: 'Сменный мастер', value: 'master' },
          { label: 'Вид, место и дата последнего планового ремонта', value: 'typeRepairLastVu23' },
          { label: 'Собственник', value: 'ownerVu23' },
          { label: 'Из под какого продукта', value: 'outGoodVu23' },
          { label: 'Категория принадлежности', value: 'affiliationCategory' }
        ]
      }
      attributeRequestsName = 'attributeRequestsVu23'
      attributeRequestName = 'attributeRequestVu23'
      break
    case 'vu22':
      documentTitle = 'ВУ22'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Вид ремонта(22)', value: 'repairType' },
        { label: 'Грузоподъемность(22)', value: 'gruzopodiemnost' },
        { label: 'Материал кузова(22)', value: 'materialKuzova' },
        { label: 'Время начала ремонта(22)', value: 'repairBeginDate', type: 'date' },
        { label: 'Время окончания ремонта(22)', value: 'repairEndDate', type: 'date' },
        { label: 'Бригадир', value: 'brigadir' },
        { label: 'Сменный мастер', value: 'master' },
        { label: 'Сменить(МЧ)', value: 'changeMP' },
        { label: 'Фамилия исполнителя(МЧ)', value: 'employeeMP' },
        { label: 'Выправить(СР)', value: 'improveSR' },
        { label: 'Заварить(СР)', value: 'zavaritSR' },
        { label: 'Фамилия исполнителя(СР)', value: 'employeeSR' }
      ]
      if (isUserKazykurt()) {
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Вид ремонта(22)', value: 'repairType' },
          { label: 'Грузоподъемность(22)', value: 'gruzopodiemnost' },
          { label: 'Материал кузова(22)', value: 'materialKuzova' },
          { label: 'Вид последнего ремонта(22)', value: 'lastRepairType' },
          { label: 'Дата последнего ремонта(22)', value: 'lastRepairDate', type: 'date' },
          { label: 'Время начала ремонта(22)', value: 'repairBeginDate', type: 'date' },
          { label: 'Время окончания ремонта(22)', value: 'repairEndDate', type: 'date' },
          { label: 'Приемщик вагонов', value: 'receiverTanks' },
          { label: 'Сменный мастер', value: 'master' },
          { label: 'Сменить(МЧ)', value: 'changeMP' },
          { label: 'Фамилия исполнителя(МЧ)', value: 'employeeMP' },
          { label: 'Выправить(СР)', value: 'improveSR' },
          { label: 'Заварить(СР)', value: 'zavaritSR' },
          { label: 'Фамилия исполнителя(Выправить СР)', value: 'employeeSR' },
          { label: 'Фамилия исполнителя(Заварить СР)', value: 'employeeSR2' }
        ]
      }
      if (isUserErtys()) {
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Вид ремонта', value: 'repairType' },
          { label: 'Грузоподъемность', value: 'gruzopodiemnost' },
          { label: 'Материал кузова', value: 'materialKuzova' },
          { label: 'Вид последнего ремонта', value: 'lastRepairType' },
          { label: 'Дата последнего ремонта', value: 'lastRepairDate', type: 'date' },
          { label: 'Время начала ремонта', value: 'repairBeginDate', type: 'date' },
          { label: 'Время окончания ремонта', value: 'repairEndDate', type: 'date' },
          { label: 'Сменный мастер', value: 'master' },
          { label: 'Сменить(МЧ)', value: 'changeMP' },
          { label: 'Фамилия исполнителя(МЧ)', value: 'employeeMP' },
          { label: 'Выправить(СР)', value: 'improveSR' },
          { label: 'Заварить(СР)', value: 'zavaritSR' },
          { label: 'Фамилия исполнителя(СР)', value: 'employeeSR' },
          { label: 'Фамилия исполнителя(Заварить СР)', value: 'employeeSR2' }
        ]
      }
      attributeRequestsName = 'attributeRequestsVu22'
      attributeRequestName = 'attributeRequestVu22'
      break
    case 'vu36':
      documentTitle = 'ВУ36'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' },
        { label: 'Инициалы дороги приписки', value: 'roadAssign36' },
        { label: 'Тип Вагона', value: 'railcarType36' },
        { label: 'Характеристика', value: 'characteristic36' },
        { label: 'Время начала ремонта', value: 'repairTime36', type: 'date' },
        { label: 'Примечание', value: 'note36' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Станция36', value: 'station' },
        { label: 'Тип ремонта36', value: 'remontType' },
        { label: 'Путь', value: 'path' },
        { label: 'Бригадир', value: 'brigadir' },
        { label: 'Слесарь подвижного состава', value: 'locksmith' },
        { label: 'Сменный мастер', value: 'masterVu36' }
      ]
      if (isUserPtc()) {
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Станция', value: 'station36' },
          { label: 'Тип ремонта', value: 'repairType36' },
          { label: 'Путь', value: 'path36' },
          { label: 'Бригадир', value: 'brigadirVu36' },
          { label: 'Приемщики ВЧД-1', value: 'wagonReceiverVu36' },
          { label: 'Мастер УВХ', value: 'masterUvhVu36' },
          { label: 'Начальник УВХ', value: 'headEnterprisePlantVu36' },
          { label: 'Дежурный по станции', value: 'stationOfficerVu36' }
        ]
      }
      if (isUserKazykurt()) {
        workplaceAttributes = [
          { label: 'Номер вагона', value: 'nomerVagona' },
          { label: 'Инициалы дороги приписки', value: 'roadAssign36' },
          { label: 'Тип Вагона', value: 'railcarType36' },
          { label: 'Характеристика', value: 'characteristic36' },
          { label: 'Время начала ремонта', value: 'repairTime36', type: 'date' },
          { label: 'Время окончания ремонта', value: 'repairEndDate36', type: 'date' },
          { label: 'Примечание', value: 'note36new' }
        ]
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Станция', value: 'station36' },
          { label: 'Тип ремонта', value: 'repairType36' },
          { label: 'Путь', value: 'path36' },
          { label: 'Бригадир', value: 'brigadirVu36' },
          { label: 'Слесарь подвижного состава', value: 'stationAttendantVu36' },
          { label: 'Сменный мастер', value: 'masterVu36' }
        ]
      }
      if (isUserErtys()) {
        workplaceAttributes = [
          { label: 'Номер вагона', value: 'nomerVagona' },
          { label: 'Инициалы дороги приписки', value: 'roadAssign36' },
          { label: 'Пути', value: 'path36' },
          { label: 'Тип Вагона', value: 'railcarType36' },
          { label: 'Характеристика', value: 'characteristic36' },
          { label: 'Время начала ремонта', value: 'repairTime36', type: 'date' },
          { label: 'Примечание', value: 'note36' }
        ]
        attributes = [
          { label: 'Номер документа', value: 'docNumber' },
          { label: 'Дата создания документа', value: 'createdDate' },
          { label: 'Станция', value: 'station36' },
          { label: 'Тип ремонта', value: 'repairType36' },
          { label: 'Время подачи на ремонтные пути', value: 'repairBeginDateVu36', type: 'date' },
          { label: 'Время окончания ремонта', value: 'repairEndDateVu36', type: 'date' },
          { label: 'Сменный мастер', value: 'masterVu36' }
        ]
      }
      attributeRequestsName = 'attributeRequestsVu36'
      attributeRequestName = 'attributeRequestVu36'
      break
    case 'du1Departure':
      documentTitle = 'ДУ-1 Убытие'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'railcarDu1MainNew' },
        { label: 'Налив пр-кт', value: 'podNaliv' },
        { label: 'Номер документа', value: 'docNumberVu20' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Пути', value: 'pathDu1List' },
        { label: 'Дежурный по станции', value: 'stationAttendantDu1Dep' },
        { label: 'Время убытие с ППС', value: 'path', type: 'depatureDatePps' },
        { label: 'Время выставление на выставочные пути', value: 'exhibitionTime', type: 'date' },
        { label: 'Готовность к отправлению', value: 'readyForDepTime', type: 'date' }
      ]
      attributeRequestsName = 'attributeRequestDocDu1MainNew'
      attributeRequestName = 'attributeRequestWorkDu1MainNew'
      break
    case 'du1MainNew':
      documentTitle = 'УВ (Прибытия)'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'railcarDu1MainNew' },
        { label: 'Налив пр-кт', value: 'podNaliv' },
        { label: 'Номер документа', value: 'docNumberVu20' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Пути', value: 'pathDu1List' },
        { label: 'Дежурный по станции', value: 'stationAttendantDu1Dep' },
        { label: 'Время убытие с ППС', value: 'path', type: 'depatureDatePps' },
        { label: 'Время выставление на выставочные пути', value: 'exhibitionTime', type: 'date' },
        { label: 'Готовность к отправлению', value: 'readyForDepTime', type: 'date' }
      ]
      attributeRequestsName = 'attributeRequestDocDu1MainNew'
      attributeRequestName = 'attributeRequestWorkDu1MainNew'
      break
    case 'statement':
      documentTitle = 'Ведомость'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' },
        { label: 'Ранее перевозимый груз', value: 'podSlivStatement' },
        { label: 'Под налив', value: 'podNalivStatement' },
        { label: 'Путь', value: 'pathStatement' },
        { label: 'Время начала', value: 'startProcStatement', type: 'date' },
        { label: 'Время окончания', value: 'endProcStatement', type: 'date' },
        { label: 'Примечание', value: 'primechaniyeStatement' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Осмотрщики нефтеналивных емкостей', value: 'receiverOfOilTanksStatement' },
        { label: 'Сменный мастер', value: 'masterStatement' },
        { label: 'Оператор', value: 'operatorStatement' },
        { label: 'Промывальщик-пропарщик', value: 'seniorSteamerStatement' }
      ]
      attributeRequestsName = 'attributeRequestsStatement'
      attributeRequestName = 'attributeRequestStatement'
      break
    case 'primaryAct':
      documentTitle = 'Первичный Акт'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Код страны', value: 'countryCodePrimary' },
        { label: 'Номер поезда', value: 'trainNumberPrimary' },
        { label: 'Неисправность', value: 'malfunctionPrimary' },
        { label: 'Дополнительной неисправности', value: 'additionalFaultPrimary' },
        { label: 'Забракован по неисправности', value: 'rejectedMalfunctionPrimary' },
        { label: 'Код неисправности', value: 'faultCodePrimary' },
        { label: 'Тип и дата последнего ремонта', value: 'lastRepairDateAndTypePrimary' },
        { label: 'Ст. осмоторщик-ремонтник вагонов “РТИ-АНПЗ”', value: 'seniorInspectorRtiPrimary' },
        { label: 'Старший осмоторщик-ремонтник вагонов ВЧД-1', value: 'seniorRepairmanPrimary' },
        { label: 'Путь', value: 'pathPrimary' },
        { label: 'Состояние', value: 'statePrimary' },
        { label: 'Представитель компании РТС оператор', value: 'representativePrimary' }
      ]
      attributeRequestsName = 'attributeRequestDocPrimaryAct'
      attributeRequestName = 'attributeRequestWorkPrimaryAct'
      break
    case 'actOfCompletion':
      documentTitle = 'Акт выполненных работ'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Код страны', value: 'countryCode' },
        { label: 'Дата отцепки', value: 'dataOtcepki' },
        { label: 'Предприятия', value: 'fabricator' },
        { label: 'Дата и вид последнего планового ремонта', value: 'dateAndTypeLastRepair' },
        { label: 'Забракован по неисправности', value: 'rejectedMalfunction' },
        { label: 'Место последнего ремонта', value: 'lastLocationRenovation' },
        { label: 'Код неисправности', value: 'faultCode' },
        { label: 'Мастер участка', value: 'masterCommission' },
        { label: 'Начальник участка', value: 'chiefComnission' },
        { label: 'Бригадир ТОР', value: 'brigadirCommission' },
        { label: 'Состояние', value: 'state' },
        { label: 'Произведённые работы', value: 'producedWorks' },
        { label: 'Представитель компании «PTC Operator»', value: 'representativePTCOperator' }
      ]
      attributeRequestsName = 'attributeRequestDocActOfCommission'
      attributeRequestName = 'attributeRequestWorkActOfCommission'
      break
    case 'actOfCommission':
      documentTitle = 'Акт комиссионного осмотра'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Код страны', value: 'countryCode' },
        { label: 'Дата отцепки', value: 'dataOtcepki' },
        { label: 'Предприятия', value: 'fabricator' },
        { label: 'Дата и вид последнего планового ремонта', value: 'dateAndTypeLastRepair' },
        { label: 'Забракован по неисправности', value: 'rejectedMalfunction' },
        { label: 'Место последнего ремонта', value: 'lastLocationRenovation' },
        { label: 'Код неисправности', value: 'faultCode' },
        { label: 'Мастер участка', value: 'masterCommission' },
        { label: 'Начальник участка', value: 'chiefComnission' },
        { label: 'Бригадир ТОР', value: 'brigadirCommission' },
        { label: 'Состояние', value: 'state' },
        { label: 'Произведённые работы', value: 'producedWorks' },
        { label: 'Представитель компании «PTC Operator»', value: 'representativePTCOperator' }
      ]
      attributeRequestsName = 'attributeRequestDocActOfCommission'
      attributeRequestName = 'attributeRequestWorkActOfCommission'
      break
    case 'uncoupledAct':
      documentTitle = 'Акт безотцепочного ремонта'
      workplaceAttributes = [
        { label: 'Номер вагона', value: 'nomerVagona' }
      ]
      attributes = [
        { label: 'Номер документа', value: 'docNumber' },
        { label: 'Дата создания документа', value: 'createdDate' },
        { label: 'Код страны', value: 'countryCodeUncoupled' },
        { label: 'Номер поезда', value: 'trainNumberUncoupled' },
        { label: 'Неисправность', value: 'malfunctionUncoupled' },
        { label: 'Тип и дата последнего ремонта', value: 'lastRepairDateAndTypeUncoupled' },
        { label: 'Ст. осмоторщик-ремонтник вагонов “РТИ-АНПЗ”', value: 'seniorInspectorRtiUncoupled' },
        { label: 'Осмоторщик-ремонтник вагонов “РТИ-АНПЗ”', value: 'inspectorRtiUncoupled' },
        { label: 'Путь', value: 'pathUncoupled' },
        { label: 'Состояние', value: 'stateUncoupled' },
        { label: 'Представитель компании РТС оператор', value: 'representativeUncoupled' }
      ]
      attributeRequestsName = 'attributeRequestDocUncoupledAct'
      attributeRequestName = 'attributeRequestWorkUncoupledAct'
      break
  }
  return { documentTitle, attributes, workplaceAttributes, jasperPath, attributeRequestsName, attributeRequestName }
}

export { getDocumentInfo }
